/* global  itemVarietiesComponent */
class CustomItemVarietiesComponent extends itemVarietiesComponent {

    getData() {
        let superData = super.getData();
        return function () {
            return Object.assign(superData(),{
                isMobile: $(window).width() <= 991,
                selectByFirtVariationByDefault: true
            });
        };
    }

    getWatch() {
        return  {
            "item": function () {
                this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code) != -1;
                this.loadVarities();
            },
            "Qty":function (){
                if(this.controlStock)
                    if(Number.isInteger(this.item.Qty) && this.item.Qty <= this.Qty) {
                        this.Qty = this.item.Qty;
                        this.alertShowMessage("Maximum item quantity reached");
                    }
            },
            "selected": function (newValue, oldValue) {
                if(newValue && newValue.Variants)
                    $(`#optionSelect${newValue.Variants.split(",")[1]}`).attr('checked', true);
            }
        };
    }

    getTemplate(){
        return `<div class="item-detail" @change.once="loadVarities">
                <div class="col-12">
                   <div class="container-fluid">
                    <div class="row" v-if="item">
                        <div class="col-sm-12 col-md-6 col-xl-4 offset-xl-2">
                            <template v-if="!selected || !selected.images">
                                <itemSliderComponent :carouselOnLeftSide="!isMobile" :useZoom="imgUseZoom && !isMobile" :slideBigImage="imgSlideBigImage" :item="item" :stockFlag="showStock" :container="true" :showCarusel="imgShowCarusel"></itemSliderComponent>
                            </template>
                            <template v-else>
                                <itemSliderComponent :carouselOnLeftSide="!isMobile" :useZoom="imgUseZoom && !isMobile" :slideBigImage="imgSlideBigImage" :item="selected" :stockFlag="showStock" :container="true" :showCarusel="imgShowCarusel"></itemSliderComponent>
                            </template>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-4">
                            <div class="product-title-container">
                                <p class="product-title">{{item.Name}}</p>
                                <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                    <span class="product-stock text-uppercase">
                                        <template v-if="!selected">
                                            <template v-if="item.Qty - item.Reserved > 0">
                                                Stock {{getMaxStockByCode(item.Code)}}
                                            </template>
                                            <template v-else>
                                                Stock agotado
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="getMaxStockByCode(selected.Code) > 0">
                                                Stock {{selected.Qty - selected.Reserved}}
                                            </template>
                                            <template v-else>
                                                Stock agotado
                                            </template>
                                        </template>  
                                    </span>
                                </template>
                            </div>
                            <div v-if="item.Comment" class="product-description">
                                <p class="subtitle">{{tr("Description")}}</p>
                                <p class="product-comment">{{item.Comment}}</p>
                            </div>
                            <div class="product-details">
                                <p class="product-code">{{tr("ECOMITEMCODE")}}: {{codeToShow}}</p>
                                <p class="product-code" v-if="$store.getters.showStock">
                                    <template v-if="!selected">
                                         Stock: {{item.Qty}}
                                    </template>
                                    <template v-else>
                                        Stock: {{selected.Qty}}
                                    </template>  
                                </p>
                                <template v-if="showClassification">
                                    <p class="subtitle">{{tr('Classifications')}}</p>
                                    <ol class="flex-column">
                                         <template v-for="cat in classificationList">
                                            <li :key="'item-cat-'+cat.name" @click="filterByCategory(cat)">
                                                <router-link :to="'/items?category=' + encodeURI(cat.code+'__'+cat.name)">
                                                    {{capitalize(cat.name)}}
                                                </router-link>
                                            </li>
                                        </template>
                                    </ol>
                                </template>
                            </div>
                            <template v-if="itemVarieties.length==0">
                                <div class="loading" >
                                    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row items-varieties" >
                                    <div class="col-12">
                                        <template v-for="option in Object.keys(options).sort()">
                                            <template v-if="option=='COLOR'">
                                                <div class="form-group row mt-2">
                                                    <label class="col-12 items-varieties-title" :for="'optionSelect'+option" >{{capitalize(option)}}: <span class="active-option">{{options[option][selectedColor]}}</span></label>
                                                    <div class="col-12 px-2 item-color-options" >
                                                        <template v-for="optionKey in Object.keys(options[option]).sort()" >
                                                            <div class="d-inline-block px-1" >
                                                                <span v-bind:class="{ 'color-active': selectedColor==optionKey }">
                                                                    <img :title="options[option][optionKey]" :key="optionKey"  :id="optionKey" :src="'../assets/'+optionKey+'_'+options[option][optionKey].replace(' ','')" :alt="options[option][optionKey]" class="rounded rounded-circle" width="20" height="20" @click="(event)=>selectVariation(event,option)">  
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="row mt-2">
                                                    <p class="col-12 mb-2 items-varieties-title" :class="['varieties-'+option.toLowerCase()]" >{{capitalize(option)}}</p>
                                                    <div class="col-12 px-2">
                                                        <p v-for="optionKey in Object.keys(options[option]).sort()" class="radio-button d-inline-block mb-2">
                                                            <input class="d-none" :name="'optionSelect'+option" type="radio" :id="'optionSelect'+optionKey" :value="optionKey" @change="(event)=>selectVariation(event,option)" />
                                                            <label :for="'optionSelect'+optionKey">{{options[option][optionKey]}}</label>
                                                        </p>
                                                    </div>
                                             </div>
                                             </template>
                                        </template>
                                        <template v-if="selected">
                                            <p> Cod. Variante : {{selected.Code}}</p>
                                            <div class="row product-shops">
                                                    <template v-if="alertMsg">
                                                        <div class="col-12 alert alert-danger" role="alert">{{tr(alertMsg)}}</div>
                                                    </template>
                                                    <div class="input-group qty-input col-4 col-md-5 pr-0 pr-xl-4">
                                                        <template v-if="controlStock">
                                                            <template v-if="getMaxStockByCode(selected.Code)">
                                                                <div class="input-group-prepend d-none d-sm-flex" >
                                                                    <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                                </div>
                                                                <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(selected.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                                <div class="input-group-append d-none d-sm-flex">
                                                                    <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <input :ref="'carCant-'+item.Code" v-model="Qty" min='0' :max="0" class="form-control inputch" type="text" disabled>
                                                                <small id="withOutStockHelpBlock" class="form-text text-muted">
                                                                    {{tr("Without Stock")}}
                                                                </small>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <div class="input-group-prepend d-none d-sm-flex" >
                                                                <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                            </div>
                                                            <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(selected.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                            <div class="input-group-append d-none d-sm-flex">
                                                                <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <span class="cart-button col-12 pl-sm-0 pl-sm-3 text-left mt-4">
                                                        <button class="btn secondary-btn px-1 px-sm-3 px-xl-5" :disabled="getMaxStockByCode(selected.Code) < 1" type="button" @click.stop="canShopItem ? addItemToCar() : null"><span class="icon"><i class="fa-icon fas fa-cart-plus"></i></span> {{tr("Load to Cart")}}</button>
                                                    </span>
                                                </div>
                                        </template>
                                        <!--div class="social-buttons col-12 text-center text-md-left mt-4 p-0 pl-md-3">
                                            <a v-if="enableFB" class="social-button mr-1" title="Facebook"  target="_blank" rel="nofollow"  :href="'https://www.facebook.com/sharer.php?u='+getItemUrl()" >
                                                <i class="icon fab fa-facebook-square"></i>
                                            </a>
                                            <a v-if="enableTW" class="social-button" title="Twitter" target="_blank" rel="nofollow" :href="'https://twitter.com/intent/tweet?url='+getItemUrl()">
                                                <i class="icon fab fa-twitter-square"></i>
                                            </a>
                                            <a v-if="enableIG" class="social-button" title="Instagram" target="_blank" rel="nofollow" :href="linkIG" >
                                                <i class="icon fab fa-instagram-square"></i>
                                            </a>
                                             <a v-if="enableLK" class="social-button" title="LinkedIn" target="_blank" rel="nofollow" :href="linkLK" >
                                                <i class="icon fab fa-linkedin"></i>
                                            </a>
                                        </div-->
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="w-100 px-3">
                            <div class="mt-3" v-if="relatedItems.length>0">
                                <homeItemCardComponent :section="{internalId:item.Code}" :items="relatedItems" :title="tr('Related Items')" :titleCenter="true" :fullScreen="true"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
			</div>`;
    }
}

CustomItemVarietiesComponent.registerComponent();